/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";

import fieldTypes from "Components/Form/fieldTypes";
import { reportTableInitialCredentials } from "Components/Table/requestTableInitialCredentials";
import { status } from "Pages/Evaluation/Operation/constants/filter";
import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import { formatStore } from "Store/ContextStore";
import updateObject from "Store/utility";
import {
  formatDateTwo,
  hmsToSecondsOnly,
  toJSONLocal,
  roundOffToHour,
} from "Utils/Utils";

import { EnhancedTableHead } from "./EnhancedTableHead";
import { ITableCredentials } from "./ITableCredentials";
import ToolBar from "./ToolBar";

export interface HeadCell {
  name: string;
  sortable?: boolean;
  alias?: string;
}

export interface SearchField {
  name: string;
  value: any;
  type: fieldTypes;
  options?: Array<any>;
}

interface StylesProps {
  rowBackColor?: string;
  rowColor?: string;
}

interface ITable {
  columns: Array<HeadCell>;
  searchFieldShow?: boolean;
  rows: any;
  hiddenHeader?: boolean;
  hiddenPagination?: boolean;
  canExportData?: boolean;
  prefix?: string;
  searchFields?: Array<SearchField>;
  handleSort?(credentials: ITableCredentials): void;
  handleRowClick?(rowId: number | string, row?: any, event?: any): void;
  handleChangePage?(credentials: ITableCredentials): void;
  handleChangeRowsPerPage?(credentials: ITableCredentials): void;
  totalRowCount?: number;
  pageNumber?: number;
  size?: number;
  addBackColor?: boolean;
  rowBackColor?: string;
  rowColor?: string;
  isActiveRowCheck?: boolean;
  handleSearchChange?(credentials: ITableCredentials): void;
  fetchRows(credentials: ITableCredentials): void;
  fetchRowsForExport?(credentials: ITableCredentials): void;
  export?: Function;
  setRequestTableCredentials?: Function;
  handleSearchFieldChange?(value: any, name: string): void;
  additionalButtons: any;
  showFilterDrawer?: boolean;
  footerProp?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ rowBackColor }: StylesProps) => ({
      width: "100%",
      "& .MuiTablePagination-actions": {
        display: "none",
      },
      "& .MuiTablePagination-root": {
        marginRight: 30,
      },
      "& .rowBackColor": {
        backgroundColor: rowBackColor ?? "#eeece5",
      },
    }),
    customRow: {
      "& .MuiTableCell-body": {
        color: (props) => props.rowColor ?? "rgba(0, 0, 0, 0.87)",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    row: {
      cursor: "pointer",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    container: {
      maxHeight: "calc(100vh - 344px)",
    },
    paginationContainer: {
      display: "flex",
      alignItems: "center",
    },
    footerPropStyle: {
      display: "flex",
      alignItems: "center",
      marginRight: 10,
    },
  }),
);

export type Order = "asc" | "desc";
export const StoreContext = React.createContext(null);

export default function Table(props: ITable): React.ReactElement {
  const classes = useStyles({
    rowBackColor: props?.rowBackColor,
    rowColor: props?.rowColor,
  });

  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(props.size);
  const [searchFields, setSearchFields] = useState(props.searchFields);
  const [showedColumns, setShowedColumns] = useState<Array<string>>([]);
  const [tableCredentials, setTableCredentials] = useState<ITableCredentials>(
    reportTableInitialCredentials,
  );
  const tableContainerRef = React.useRef(null);
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const resetFieldValues = (): void => {
    const updatedSearchFields = [...searchFields].map((field) => {
      if (field.name == "createdDate") {
        field.value = [
          formatDateTwo(new Date(new Date().setHours(0, 0, 0, 0))),
          formatDateTwo(roundOffToHour(new Date())),
        ];
      } else field.value = "";

      return field;
    });
    const updatedState = updateObject(tableCredentials, {
      fieldSet: {
        createdDate: [
          formatDateTwo(new Date(new Date().setHours(0, 0, 0, 0))),
          formatDateTwo(roundOffToHour(new Date())),
        ],
      },
      page: 0,
    });
    setTableCredentials(updatedState);
    props.fetchRows(updatedState);
    setSearchFields(updatedSearchFields);
  };

  const searchByFields = (): void => {
    const fieldSet = {};
    searchFields.forEach((field) => {
      if (field.value) {
        if (typeof field.value === "object") {
          field.value[0] == null ||
          (field.value.length === 1 && field.value[0] == "") ||
          (field.value[0] == "" && field.value[1] == "")
            ? delete fieldSet[field.name]
            : (fieldSet[field.name] = field.value);
        } else fieldSet[field.name] = [field.value];
      }
    });

    const updatedState = updateObject(tableCredentials, {
      fieldSet,
      page: 0,
    });
    setTableCredentials(updatedState);
    props.fetchRows(updatedState);
  };

  const store = formatStore({
    showedColumns: [showedColumns, setShowedColumns],
    rowsPerPage: [rowsPerPage, setRowsPerPage],
    columns: [props.columns, null],
    searchFields: [searchFields, setSearchFields],
    rows: [props.rows, null],
    searchByFields,
    resetFieldValues,
    setOptionsByDictionaryId: () => {},
    handleSearchFieldChange: (
      value: any,
      name: string,
      fieldType: fieldTypes,
    ) => {
      const updatedSearchFields = [...searchFields];
      updatedSearchFields.map((field, index) => {
        if (field.name == name) {
          if (fieldType === fieldTypes.TIME_RANGE) {
            const updatedValue = [
              value && value[0]
                ? hmsToSecondsOnly(value[0].toString().slice(16, 24))
                : "",
              value && value[1]
                ? hmsToSecondsOnly(value[1].toString().slice(16, 24))
                : "",
            ];
            value = updatedValue;
          }
          if (fieldType === fieldTypes.DATETIME_RANGE) {
            const updatedValue = [
              value && value[0] && value[0] != ""
                ? value[0] instanceof Date
                  ? toJSONLocal(value[0])
                  : value[0]
                : "",
              value && value[1] && value[1] != ""
                ? value[1] instanceof Date
                  ? toJSONLocal(value[1])
                  : value[1]
                : "",
            ];
            value = updatedValue;
          }
          if (fieldType === fieldTypes.SELECT) {
            const updatedValue = value && value.map((item) => item.value);
            value = updatedValue;
          }
          if (fieldType === fieldTypes.CHECKBOX) {
            const updatedValue = value && "true";
            value = updatedValue;
          }
          if (!(value instanceof Array)) value = [value];

          updatedSearchFields[index].value = value;
        }
      });
      setSearchFields(updatedSearchFields);
    },
  });

  useEffect(() => {
    setShowedColumns(props.columns.map((column) => column.name));
  }, [props.columns]);

  useEffect(() => {
    setSearchFields(props.searchFields);
  }, [props.searchFields]);

  useEffect(() => {
    props.setRequestTableCredentials &&
      props.setRequestTableCredentials(tableCredentials);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
      tableContainerRef.current.scrollLeft = 0;
    }
  }, [tableCredentials]);

  const handleSearchChange = (value: string): void => {
    const updatedState = updateObject(tableCredentials, {
      fullTextSearch: value,
      page: 0,
    });
    setTableCredentials(updatedState);
    props.fetchRows(updatedState);
    if (props.handleSearchChange) props.handleSearchChange(updatedState);
  };

  const handleSort = (_event, property): void => {
    let isAsc = false;
    if (tableCredentials.fieldName)
      isAsc =
        tableCredentials.fieldName === property &&
        tableCredentials.order === "asc";

    const updatedState = updateObject(tableCredentials, {
      order: isAsc ? "desc" : "asc",
      fieldName: property,
      page: 0,
    });
    setTableCredentials(updatedState);
    props.fetchRows(updatedState);
    if (props.handleSort) props.handleSort(updatedState);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => {
    const updatedState = updateObject(tableCredentials, { page: page - 1 });
    setTableCredentials(updatedState);
    props.fetchRows(updatedState);
    if (props.handleChangePage) props.handleChangePage(updatedState);
  };

  const handleChangeRowsPerPage = (event): void => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    const updatedState = updateObject(tableCredentials, {
      recordSize: value,
      page: 0,
    });
    setTableCredentials(updatedState);
    props.fetchRows(updatedState);
    if (props.handleChangeRowsPerPage)
      props.handleChangeRowsPerPage(updatedState);
  };

  const handleRowClick = (rowId: string | number, row, event): void => {
    if (
      props.isActiveRowCheck &&
      JSON.parse(localStorage.getItem("authorities"))
        ?.task_admin_all_tasks_access === undefined
    ) {
      const owners = row?.owners && row?.owners.split(" , ");
      const fullName =
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName");
      const isActiveRowClick =
        owners && owners.find((owner) => owner === fullName);
      isActiveRowClick !== undefined &&
        props.handleRowClick &&
        props.handleRowClick(rowId, row, event);
    } else props.handleRowClick && props.handleRowClick(rowId, row, event);
  };

  useEffect(() => {
    if (requestState.isEditRequest) props.fetchRows(tableCredentials);
  }, [requestState.isEditRequest]);

  return (
    <StoreContext.Provider value={store}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {!props.hiddenHeader && (
            <ToolBar
              fetchRows={props.fetchRows}
              fetchRowsForExport={props.fetchRowsForExport}
              additionalButtons={props.additionalButtons}
              dense={dense}
              export={props.export}
              canExportData={props.canExportData}
              prefix={props.prefix}
              setDense={setDense}
              handleSearchChange={handleSearchChange}
              searchFieldShow={props.searchFieldShow}
              tableCredentials={tableCredentials}
              showFilterDrawer={props.showFilterDrawer ?? true}
            />
          )}
          <TableContainer className={classes.container} ref={tableContainerRef}>
            <MuiTable
              className={classes.table}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}
              aria-label='sticky table'
              stickyHeader>
              <EnhancedTableHead
                classes={classes}
                order={tableCredentials.order}
                orderBy={tableCredentials.fieldName}
                onRequestSort={handleSort}
                rowCount={props.rows ? Object.keys(props.rows).length : 0}
              />
              <TableBody>
                {props.rows
                  ? Object.keys(props.rows).map((rowId) => {
                      const row = props.rows[rowId];
                      const labelId = `enhanced-table-checkbox-${rowId}`;
                      return (
                        <TableRow
                          className={clsx(
                            classes.row,
                            row.evaluationStatus === status.DECLINED &&
                              classes.customRow,
                            {
                              ["rowBackColor"]:
                                props.rowBackColor && row?.parentId === null,
                            },
                          )}
                          hover
                          onClick={(event) => handleRowClick(rowId, row, event)}
                          role='checkbox'
                          tabIndex={-1}
                          key={labelId}>
                          {props.columns.map((column, index) => {
                            if (showedColumns.includes(column.name))
                              return (
                                <TableCell
                                  align='center'
                                  className={
                                    column.name === "topicColumn" ? "topic" : ""
                                  }
                                  key={index}>
                                  {row[column.name]}
                                </TableCell>
                              );
                          })}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <TableContainer>
            <MuiTable>
              {!props.hiddenPagination && (
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div className={classes.paginationContainer}>
                          <TablePagination
                            rowsPerPageOptions={[50, 100, 200]}
                            component='div'
                            count={props.totalRowCount}
                            page={props.pageNumber}
                            rowsPerPage={props.size}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                          <Pagination
                            count={Math.ceil(props.totalRowCount / props.size)}
                            page={props.pageNumber + 1}
                            boundaryCount={2}
                            onChange={handleChangePage}
                          />
                        </div>
                        {props.footerProp && (
                          <div className={classes.footerPropStyle}>
                            {props.footerProp}
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </MuiTable>
          </TableContainer>
        </Paper>
      </div>
    </StoreContext.Provider>
  );
}
