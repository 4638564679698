import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, styled } from "@material-ui/core/styles";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { StoreContext } from "Components/Layout/AppLayout";
import React, { useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import SoftPhoneStore from "Store/Actions/SoftPhone";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
    },
    inputWrapper: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  }),
);

const Inputs = ({ makeCall }) => {
  const { translate } = useTranslation();
  const classes = useStyles();
  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );
  const softStore: any = useContext(StoreContext);

  const numberInputChanged = (event) => {
    const { phoneNumber, dtmf, currentLine } = softPhoneState;
    const digit = event.target.value;

    if (!softPhoneState.lines[softPhoneState.currentLine]) {
      if (phoneNumber.length > 20) return;

      softPhoneStore.setPhoneNumber({
        ...softPhoneState.phoneNumber,
        [softPhoneState.currentLine]: digit,
      });
    } else {
      if (dtmf.length > 5) return;
      softPhoneStore.setDTMF(digit);

      const sipCallId = softPhoneState.lines[currentLine].sipCallId;
      softStore && softStore.sipStorage.sendDtmf(sipCallId, digit);
    }
  };

  const handleKeyDown = (event): void => {
    if (event.key === "Enter") makeCall();
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid className={classes.inputWrapper} item xs={6}>
          <Input
            type='text'
            value={
              !softPhoneState.lines[softPhoneState.currentLine]
                ? softPhoneState.phoneNumber[softPhoneState.currentLine]
                : softPhoneState.dtmf
            }
            onChange={numberInputChanged}
            onKeyDown={handleKeyDown}
            placeholder={translate("softphone_start_dialing")}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Inputs;

const Input = styled("input")({
  backgroundColor: "inherit",
  paddingTop: "10px",
  outline: "none",
  border: "none",
  color: "#050038",
  fontSize: 18,
  letterSpacing: "0.02em",
  lineHeight: "160%",
  boxShadow: "none",
  textAlign: "center",
  "&::placeholder": {
    color: "#050038",
    fontSize: 18,
    letterSpacing: "0.02em",
    lineHeight: "160%",
  },
  "&:focus": {
    border: "none",
    outline: "none",
  },
});
