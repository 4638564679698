import axios from "Api/axios";

import WebConf, {
  BreakManagementUrl,
  ConfigurationServerUrl,
  RoleManagementUrl,
  UserManagementUrl,
  FileServerUrl,
  defaultRoute,
  TaskManagementUrl,
  AuthServerUrl,
  OmniServerUrl,
} from "Config/WebConf";
import { fetchData } from "networking";

import { breakType as breakTypes } from "Pages/Configuration/BreakManagement/constants";

import { formatDateFive, formatDateThree } from "Utils/Utils";

import { IRoleInfo } from "./interfaces";
import {
  ICallbackConfig,
  IUsersCallbackConfig,
} from "./interfaces/configuration";

// User Management API

// GET
export const getOptionsByDictionaryId = (dictionaryId: number): Promise<any> =>
  axios
    .get(
      `${ConfigurationServerUrl}${WebConf.configuration.getOptionsByDictionaryId}?dictionary_id=${dictionaryId}`,
    )
    .then((response) => response && response.data);

export const getUsers = (page: number, size: number): Promise<any> =>
  axios
    .get(
      `${UserManagementUrl}${WebConf.configuration.getUsers}?page=${page}&size=${size}`,
    )
    .then((response) => response && response.data);

export const searchUsers = (
  value: string,
  page: number,
  size: number,
): Promise<any> =>
  axios
    .get(
      `${UserManagementUrl}${WebConf.configuration.searchUsers}?search=${value}&page=${page}&size=${size}`,
    )
    .then((response) => response && response.data);

export const getAllUsers = (): Promise<any> =>
  axios
    .get(`${UserManagementUrl}${WebConf.configuration.getAllUsers}`)
    .then((response) => response && response.data);

export const getUserAvailability = (): Promise<any> =>
  axios
    .get(`${UserManagementUrl}${WebConf.configuration.getUserAvailability}`)
    .then((response) => response && response.data);

export const getUsersInChatQueue = (): Promise<any> =>
  axios
    .get(`${UserManagementUrl}${WebConf.configuration.getUsersInChatQueue}`)
    .then((response) => response && response.data);

export const findUserById = (id: number): Promise<any> =>
  axios
    .get(`${UserManagementUrl}${WebConf.configuration.findUserById}/${id}`)
    .then((response) => response && response.data);

export const getAllOmniChannelQueueList = (): Promise<any> =>
  axios
    .get(UserManagementUrl + WebConf.configuration.getAllOmniChannelQueueList)
    .then((response) => response && response.data);

export const getOmniChannelQueueListById = (id: number): Promise<any> =>
  axios
    .get(
      UserManagementUrl +
        WebConf.configuration.getOmniChannelQueueListById +
        "=" +
        id,
    )
    .then((response) => response && response.data);

export const getQueueGroups = (): Promise<any> =>
  axios
    .get(ConfigurationServerUrl + WebConf.configuration.getQueueGroups)
    .then((response) => response && response.data);

export const downloadAttachment = async (path: string): Promise<any> => {
  return await fetchData(
    UserManagementUrl + WebConf.file.downloadAttachment + "/" + path,
  );
};

export const getOmniWebsocketIsConnect = (): Promise<any> =>
  axios
    .get(
      ConfigurationServerUrl + WebConf.configuration.getOmniWebsocketIsConnect,
    )
    .then((response) => response);

// DELETE
export const deleteUser = async (id: number): Promise<boolean> =>
  axios
    .delete(`${UserManagementUrl}${WebConf.configuration.deleteUser}/${id}`)
    .then((response) => response.status === 200);

// POST
export const usersIsActive = (id: number, isActive: boolean): Promise<any> =>
  axios
    .post(
      `${UserManagementUrl}${WebConf.configuration.usersIsActive}/${id}/activation?isActive=${isActive}`,
    )
    .then((response) => (response && response.data ? true : false));

export const userDisableMFA = (id: number, mfaUser: boolean): Promise<any> =>
  axios
    .post(
      `${UserManagementUrl}${WebConf.configuration.userDisableMFA}/${id}/mfa?isMfaUser=${mfaUser}`,
    )
    .then((response) => (response && response.data ? true : false));

export const resetUserPassword = (id: number): Promise<boolean> =>
  axios
    .post(
      `${UserManagementUrl}${WebConf.configuration.resetUserPassword}/${id}/password/reset`,
    )
    .then((response) => response.status === 200);

export const setForcePasswordUpdate = (id: number): Promise<boolean> =>
  axios
    .post(
      `${UserManagementUrl}${WebConf.configuration.forcePasswordUpdate}/${id}/force-password-change`,
    )
    .then((response) => response.status === 200);

export const uploadAttachments = async (attachments: FormData): Promise<any> =>
  axios
    .post(`${FileServerUrl}${WebConf.file.uploadAttachments}`, attachments)
    .then((response) => response && response.data);

export const passwordChange = (
  oldPassword: string,
  newPassword: string,
  confirmationPassword: string,
): Promise<any> => {
  const body = {
    oldPassword,
    newPassword,
    confirmationPassword,
  };

  return axios.post(
    UserManagementUrl + WebConf.configuration.passwordChange,
    body,
  );
};

export const updateOmniChannelQueueList = async (
  id: number,
  queueNames: string[],
  firstname: string,
  lastname: string,
  email: string,
): Promise<any> => {
  const body = {
    userId: id,
    queueNameList: queueNames,
    firstname,
    lastname,
    email,
  };
  return axios
    .post(
      UserManagementUrl + WebConf.configuration.updateOmniChannelQueueList,
      body,
    )
    .then((response) => (response && response.data ? true : false));
};

export const addUser = async (
  id: number,
  username: string,
  userNo: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmationPassword: string,
  profileImage: string,
  roles: Array<any>,
  contextId: number | null,
): Promise<any> => {
  const body: BodyInit = JSON.stringify({
    id,
    username,
    userNo,
    firstName,
    lastName,
    email,
    password,
    confirmationPassword,
    photo: profileImage,
    roles,
    contextId,
  });

  const params: RequestInit = {
    method: "POST",
    body,
  };

  return await fetchData(
    UserManagementUrl + WebConf.configuration.addUser,
    params,
  );
};

export const updateUser = async (
  id: number,
  username: string,
  userNo: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmationPassword: string,
  photo: string | null,
  roles: Array<any>,
  contextId: number | null,
): Promise<boolean> => {
  const body: BodyInit = JSON.stringify({
    id,
    username,
    userNo,
    firstName,
    lastName,
    email,
    password,
    confirmationPassword,
    photo,
    roles,
    contextId,
  });

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    UserManagementUrl + WebConf.configuration.updateUser,
    params,
  );

  if (response && response.status === 200) return true;
  else return false;
};

export const setCodeVerify = (
  username: string,
  code: string,
): Promise<boolean> => {
  const body = {
    username,
    code,
  };
  return axios
    .post(UserManagementUrl + WebConf.configuration.setCodeVerify, body)
    .then((response) => response.status === 200);
};

export const getRegex = async (): Promise<string> => {
  const response = fetch(`${AuthServerUrl}${WebConf.auth.getRegex}`, {
    method: "GET",
    redirect: "follow",
  });
  const resultText = (await response).text();
  return resultText;
};

// Break Management API

// GET
export const getAllBreaksList = (): Promise<any> =>
  axios
    .get(BreakManagementUrl + WebConf.configuration.getAllBreaksList)
    .then((response) => response && response.data);

export const getAllActiveBreaksList = async (): Promise<any> =>
  axios
    .get(BreakManagementUrl + WebConf.configuration.getAllActiveBreaksList)
    .then((response) => response && response.data);

export const findBreakById = (id: number): Promise<any> =>
  axios
    .get(
      BreakManagementUrl +
        WebConf.configuration.findBreakById +
        "?breakId=" +
        id,
    )
    .then((response) => response && response.data);

export const getAllBreakListByUserId = (id): Promise<any> =>
  axios
    .get(
      BreakManagementUrl +
        WebConf.configuration.getAllBreakListByUserId +
        "=" +
        id,
    )
    .then((response) => response && response.data);

// DELETE
export const deleteBreak = (id: number): Promise<any> =>
  axios
    .delete(
      BreakManagementUrl + WebConf.configuration.deleteBreak + "?breakId=" + id,
    )
    .then((response) => (response && response.data ? true : false));

// POST
export const addBreak = (
  id: number,
  name: string,
  maxAvailableUser: number,
  userBased: number,
  timeFrom: string,
  timeTo: string,
  birdefe: boolean,
  duration: number,
  allowCallTime: number,
  breakUserList: Array<object>,
  isPermissionRequired: boolean,
): Promise<any> => {
  const body = {
    id,
    name,
    maxAvailableUser: +maxAvailableUser,
    userBased: breakUserList.length === 0 ? 0 : userBased,
    timeFrom,
    timeTo,
    birdefe,
    duration,
    allowCallTime,
    breakUserList: userBased === 1 ? breakUserList : [],
    breakType: breakTypes.Flexible,
    active: true,
    isPermissionRequired,
  };

  return axios
    .post(BreakManagementUrl + WebConf.configuration.addBreak, body)
    .then((response) => (response && response.data ? true : false));
};

export const updateBreak = (
  id: number,
  name: string,
  breakType: breakTypes,
  maxAvailableUser: number,
  userBased: number,
  timeFrom: string,
  timeTo: string,
  birdefe: boolean,
  duration: number,
  active: boolean,
  allowCallTime: number,
  breakUserList: Array<object>,
  isPermissionRequired: boolean,
): Promise<any> => {
  const body = {
    id,
    name,
    breakType,
    maxAvailableUser: +maxAvailableUser,
    userBased: breakUserList.length === 0 ? 0 : userBased,
    timeFrom,
    timeTo,
    birdefe,
    duration,
    active,
    allowCallTime,
    breakUserList: userBased === 1 ? breakUserList : [],
    isPermissionRequired,
  };

  return axios
    .post(BreakManagementUrl + WebConf.configuration.updateBreak, body)
    .then((response) => (response && response.data ? true : false));
};

export const toggleBreak = (newBreak): Promise<any> => {
  const body: BodyInit = JSON.stringify(newBreak);

  return axios
    .post(BreakManagementUrl + WebConf.configuration.updateBreak, body)
    .then((response) => (response && response.data ? true : false));
};

export const takeBreak = async (
  userId: number,
  breakId: number,
  callTime: number,
  isPermissionRequired: boolean,
): Promise<any> => {
  const body = {
    userId,
    breakId,
    callTime,
    isPermissionRequired: isPermissionRequired ?? false,
  };

  return axios
    .post(BreakManagementUrl + WebConf.configuration.takeBreak, body)
    .then(
      (response) =>
        response && { data: response.data, status: response.status },
    );
};

export const requestBreak = (userId: number): Promise<any> => {
  const body = {
    userId,
  };

  return axios
    .post(BreakManagementUrl + WebConf.configuration.requestBreak, body)
    .then((response) => response && response.data);
};

export const cancelBreak = (userId: number): Promise<any> => {
  const body = {
    userId,
  };

  return axios
    .post(BreakManagementUrl + WebConf.configuration.cancelBreak, body)
    .then((response) => response && response.data);
};

export const closeBreak = (
  userId: number,
  breakId: number,
  callTime: number,
  isSocketDisconnected = false,
): Promise<boolean> => {
  const body = {
    userId,
    breakId,
    callTime,
    isSocketDisconnected,
  };

  return axios
    .post(BreakManagementUrl + WebConf.configuration.closeBreak, body)
    .then((response) => (response && response.data ? true : false));
};

export const checkBreak = (userId: string): Promise<any> => {
  const body = {
    userId,
  };

  return axios
    .post(BreakManagementUrl + WebConf.configuration.checkBreak, body)
    .then((response) => (response && response.data ? true : false));
};

// Break Management API

// GET
export const getAllRoleList = (page: number, size: number): Promise<any> =>
  axios
    .get(
      RoleManagementUrl +
        WebConf.configuration.getAllRoleList +
        "?page=" +
        page +
        "&size=" +
        size,
    )
    .then((response) => response && response.data);

export const getAllRoles = (): Promise<any> =>
  axios
    .get(RoleManagementUrl + WebConf.configuration.getAllRoles)
    .then((response) => response && response.data);

export const getExtendedUsersList = (): Promise<any> =>
  axios
    .get(RoleManagementUrl + WebConf.configuration.getExtendedUsersList)
    .then((response) => response && response.data);

export const getAllPermissionList = (): Promise<any> =>
  axios
    .get(RoleManagementUrl + WebConf.configuration.getAllPermissionList)
    .then((response) => response && response.data);

export const findRoleById = (id: number): Promise<any> =>
  axios
    .get(RoleManagementUrl + WebConf.configuration.findRoleById + "/" + id)
    .then((response) => response && response.data);

export const getConfigForCall = (): Promise<any> =>
  axios
    .get(ConfigurationServerUrl + WebConf.configuration.getConfigForCall)
    .then((response) => response && response.data);

export const getConfigForClassifier = (): Promise<any> =>
  axios
    .get(ConfigurationServerUrl + WebConf.configuration.getConfigForClassifier)
    .then((response) => response && response.data);

export const getAutoAcw = async (): Promise<any> => {
  const response = await fetchData(
    ConfigurationServerUrl + WebConf.configuration.getAutoAcw,
  );
  if (response && response.status === 200) {
    try {
      const result = await response.text();
      return result === "true";
    } catch (error) {
      return false;
    }
  } else return false;
};

// DELETE
export const deleteRole = async (id: number): Promise<any> =>
  axios
    .delete(`${RoleManagementUrl}${WebConf.configuration.deleteRole}/${id}`)
    .then(
      (response) =>
        response && { data: response.data, status: response.status },
    );

// POST
export const addUserToRole = (
  id: number,
  roleInfo: IRoleInfo,
): Promise<any> => {
  return axios
    .post(
      RoleManagementUrl + WebConf.configuration.addUserToRole + "?id=" + id,
      roleInfo.users,
    )
    .then((response) => (response && response.data ? true : false));
};

export const saveRole = async (data): Promise<any> =>
  axios
    .post(RoleManagementUrl + WebConf.configuration.saveRole, data)
    .then((response) => (response && response.data ? true : false));

export const toggleAutoAcw = (isEnabled: boolean): Promise<any> => {
  const body = {
    acw: isEnabled,
  };

  return axios
    .post(ConfigurationServerUrl + WebConf.configuration.toggleAutoAcw, body)
    .then((response) => (response && response.data ? true : false));
};

// IVR Announce Management

// GET
export const getIvrAnnounce = (): Promise<any> =>
  axios
    .get(defaultRoute + WebConf.configuration.getIvrAnnounce)
    .then((response) => response && response.data);

export const getSoundFile = async (fileName: string) => {
  const response = await fetchData(
    defaultRoute + WebConf.configuration.getSoundFile + "?fileName=" + fileName,
  );
  if (response && response.status === 200) {
    try {
      const buffer = await response.arrayBuffer();
      const audioBlob = new Blob([buffer], { type: "audio/wav" });
      return window.URL.createObjectURL(audioBlob);
    } catch (error) {
      console.error("Error fetching audio:", error);
      return "";
    }
  } else {
    return "";
  }
};

// POST
export const saveIvrAnnounce = (data): Promise<any> =>
  axios
    .post(ConfigurationServerUrl + WebConf.configuration.saveIvrAnnounce, data)
    .then((response) => (response && response.status === 200 ? true : false));

export const addIvrAnnounce = (data): Promise<any> =>
  axios
    .post(
      ConfigurationServerUrl +
        WebConf.configuration.addIvrAnnounce +
        "?name=" +
        data.name,
      data.file,
    )
    .then((response) => (response && response.status === 200 ? true : false));

// DELETE
export const deleteIvrAnnounce = async (id: number): Promise<boolean> =>
  axios
    .delete(
      `${UserManagementUrl}${WebConf.configuration.deleteIvrAnnounce}/${id}`,
    )
    .then((response) => response.status === 200);

export const updateIvrAnnounce = (id, name): Promise<boolean> =>
  axios
    .put(
      ConfigurationServerUrl +
        WebConf.configuration.updateIvrAnnounce +
        "/" +
        id +
        "?name=" +
        name,
    )
    .then((response) => (response && response ? true : false));

// Get Call Contexts

export const getAllContexts = (): Promise<any> =>
  axios
    .get(defaultRoute + WebConf.contexts.getAllContexts)
    .then((response) => response && response.data);

// Callback Management API

// GET
export const getAllConfigUsersForCallback = (): Promise<any> =>
  axios
    .get(ConfigurationServerUrl + WebConf.configuration.getAllConfigUsers)
    .then((response) => response && response.data);

export const getConfigUsersForCallback = (): Promise<
  Array<IUsersCallbackConfig>
> =>
  axios
    .get(ConfigurationServerUrl + WebConf.configuration.configUsers)
    .then((response) => response && response.data);

export const getConfigForCallback = (): Promise<ICallbackConfig> =>
  axios
    .get(ConfigurationServerUrl + WebConf.configuration.callbackConfig)
    .then((response) => response && response.data);

// POST
export const createConfigForCallback = (data): Promise<boolean> =>
  axios
    .post(ConfigurationServerUrl + WebConf.configuration.callbackConfig, data)
    .then((response) => (response && response.data ? true : false));

export const addUsersToConfigForCallback = (data): Promise<boolean> =>
  axios
    .post(ConfigurationServerUrl + WebConf.configuration.configUsers, data)
    .then((response) => (response && response.status === 201 ? true : false));

// PUT
export const updateConfigForCallback = (data): Promise<boolean> =>
  axios
    .put(ConfigurationServerUrl + WebConf.configuration.callbackConfig, data)
    .then((response) => (response && response ? true : false));

// PATCH
export const updateConfigUsersForCallback = (
  id: number,
  body: any,
): Promise<boolean> =>
  axios
    .patch(
      ConfigurationServerUrl + WebConf.configuration.updateConfigUsers(id),
      body,
    )
    .then((response) => (response && response.status === 200 ? true : false));

// DELETE
export const removeConfigUsersForCallback = (id: number): Promise<boolean> =>
  axios
    .delete(
      ConfigurationServerUrl + WebConf.configuration.updateConfigUsers(id),
    )
    .then((response) => (response && response.status === 204 ? true : false));

// Dictionary Management API

// GET
export const getAllDictionaries = (): Promise<any> =>
  axios
    .get(TaskManagementUrl + WebConf.configuration.getAllDictionaries)
    .then((response) => response && response.data);

export const getDictionaryById = (id: number): Promise<any> =>
  axios
    .get(
      `${TaskManagementUrl}${WebConf.configuration.getAllDictionaries}/${id}`,
    )
    .then((response) => response && response.data);

// POST
export const saveDictionary = (data): Promise<boolean> =>
  axios
    .post(TaskManagementUrl + WebConf.configuration.saveDictionary, data)
    .then((response) => (response && response.data ? true : false));

// PUT
export const toggleDictionaryActivation = (
  id: number,
  isActive: boolean,
): Promise<any> => {
  let checkIsActive = "activate";

  isActive === false
    ? (checkIsActive = "deactivate")
    : (checkIsActive = "activate");

  return axios
    .put(
      TaskManagementUrl +
        WebConf.configuration.toggleDictionaryActivation(id, checkIsActive),
    )
    .then((response) => response && response.data);
};

// DELETE
export const deleteDictionaryItem = (
  id: number,
  itemId: number,
): Promise<boolean> =>
  axios
    .delete(
      TaskManagementUrl +
        WebConf.configuration.deleteDictionaryItem(id, itemId),
    )
    .then((response) => (response && response.data ? true : false));

// Queue Management

//Post
export const assignUsersToOmniQueue = (data, queueName): Promise<any> =>
  axios
    .post(
      OmniServerUrl +
        WebConf.configuration.assignUsersToOmniQueue +
        "?queueName=" +
        queueName,
      data,
    )
    .then((response) => response);

export const assignUsersToVoipQueue = (data, queueName): Promise<any> =>
  axios
    .post(
      defaultRoute +
        WebConf.configuration.assignUsersToVoipQueue +
        "?queueName=" +
        queueName,
      data,
    )
    .then((response) => response);

// GET
export const getAllVoipQueueList = (page, size): Promise<any> =>
  axios
    .get(
      defaultRoute +
        WebConf.configuration.getAllVoipQueueList +
        "?page=" +
        page +
        "&size=" +
        size,
    )
    .then((response) => response && response.data);

export const getAllOmniQueueList = (page, size): Promise<any> =>
  axios
    .get(
      OmniServerUrl +
        WebConf.configuration.getAllOmniQueueList +
        "?page=" +
        page +
        "&size=" +
        size,
    )
    .then((response) => response && response.data);

export const getUsersByOmniQueueName = (queueName): Promise<any> =>
  axios
    .get(
      OmniServerUrl +
        WebConf.configuration.getUsersByOmniQueueName +
        "/" +
        queueName,
    )
    .then((response) => response && response.data);

export const getUsersByVoipQueueName = (queueName): Promise<any> =>
  axios
    .get(
      defaultRoute +
        WebConf.configuration.getUsersByVoipQueueName +
        "/" +
        queueName,
    )
    .then((response) => response && response.data);

// Auto Reply Message API

// GET
export const getDefaultAutoReplyMessage = (): Promise<any> =>
  axios
    .get(OmniServerUrl + WebConf.configuration.getDefaultAutoReplyMessage)
    .then((response) => (response.status === 200 ? response.data : []));

export const getCustomAutoReplyMessage = (): Promise<any> =>
  axios
    .get(OmniServerUrl + WebConf.configuration.getCustomAutoReplyMessage)
    .then((response) => (response.status === 200 ? response.data : []));

// POST
export const saveAutoReplyMessage = async (data) => {
  const body = {
    name: data.name,
    autoReplyMessage: data.autoReplyMessage,
    fromDateTime: data.fromDateTime ? formatDateThree(data.fromDateTime) : "",
    toDateTime: data.toDateTime ? formatDateThree(data.toDateTime) : "",
    queueId: data.queues,
  };
  return axios
    .post(OmniServerUrl + WebConf.configuration.saveAutoReplyMessage, body)
    .then((response) => (response ? response.data : false));
};

export const getAutoReplyMessageById = (id): Promise<any> =>
  axios
    .post(
      OmniServerUrl + WebConf.configuration.getAutoReplyMessageById + "/" + id,
    )
    .then((response) => response);

// PUT
export const updateAutoReplyMessage = (data): Promise<boolean> => {
  const body = {
    id: data.id,
    name: data.name,
    autoReplyMessage: data.autoReplyMessage,
    isActive: data.isActive,
    fromDateTime:
      typeof data.fromDateTime === "object"
        ? formatDateThree(data.fromDateTime)
        : data.fromDateTime
        ? formatDateFive(data.fromDateTime)
        : "",
    toDateTime:
      typeof data.toDateTime === "object"
        ? formatDateThree(data.toDateTime)
        : data.toDateTime
        ? formatDateFive(data.toDateTime)
        : "",
    queueId: data.queues,
  };
  return axios
    .put(OmniServerUrl + WebConf.configuration.updateAutoReplyMessage, body)
    .then((response) => response.status === 200);
};

// DELETE
export const deleteAutoReplyMessage = async (id: number): Promise<boolean> =>
  axios
    .delete(
      `${OmniServerUrl}${WebConf.configuration.deleteAutoReplyMessage}/${id}`,
    )
    .then((response) => response.status === 200);

// Predefined Message API

// GET
export const getPreDefinedMessage = (
  page: number,
  size: number,
): Promise<any> =>
  axios
    .get(
      OmniServerUrl +
        WebConf.configuration.getPreDefinedMessage +
        "?page=" +
        page +
        "&size=" +
        size,
    )
    .then((response) => (response.status === 200 ? response.data : []));

export const getAllPreDefinedMessage = (): Promise<any> =>
  axios
    .get(OmniServerUrl + WebConf.configuration.getAllPreDefinedMessage)
    .then((response) => response);

// POST
export const savePreDefinedMessage = async (data) => {
  const body = {
    name: data.name,
    content: data.content,
  };
  return axios
    .post(OmniServerUrl + WebConf.configuration.savePreDefinedMessage, body)
    .then((response) => response.status === 200);
};

// PUT
export const updatePreDefinedMessage = async (data) => {
  return axios
    .put(OmniServerUrl + WebConf.configuration.updatePreDefinedMessage, data)
    .then((response) => response.status === 200);
};

// PATCH
export const togglePredefinedMessage = (id: number): Promise<boolean> => {
  return axios
    .patch(
      OmniServerUrl +
        WebConf.configuration.updatePreDefinedMessage +
        "?autoChatId=" +
        id,
    )
    .then((response) => response.status === 200);
};
// DELETE
export const deletePreDefinedMessage = async (id: number): Promise<boolean> =>
  axios
    .delete(
      `${OmniServerUrl}${WebConf.configuration.deletePreDefinedMessage}/${id}`,
    )
    .then((response) => response.status === 200);
