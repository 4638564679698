/* eslint-disable eqeqeq */
import { callTypes } from "Components/SoftPhone/constants";
import { sizes } from "Components/SoftPhone/constants";

import _ from "lodash";

import {
  IAction,
  ISoftPhoneReducer,
} from "./interfaces/softPhoneReducer.types";
import { softPhoneActions as actionTypes } from "../Actions/actionTypes";
import { updateObject } from "../utility";

const initialState: ISoftPhoneReducer = {
  phoneConnected: false,
  phonePending: false,
  registered: false,
  isSavedInCall: true,
  isCallContinuing: false,
  isBreakInCall: false,
  isBreakOnHold: false,
  isSavedBeforeHoldCall: false,
  willBeSavedAfterHoldCallOver: false,
  isSpyCallActive: false,
  isCompleteCallByAgent: false,
  isCallInfoActive: false,
  isSavedBeforeACWCall: false,
  willBeSavedAfterACWCallOver: false,
  isACWCallOver: false,
  currentLine: 0,
  open: false,
  phoneNumber: {
    0: '',
    1: '',
    2: '',
  },
  dtmf: "",
  contextName: "",
  triggerCall: false,
  triggerOutgoingCall: false,
  confrimRegPageSave: null,
  size: sizes.default,
  lines: {
    0: null,
    1: null,
    2: null,
  },
  linesAfterAccepted: {
    0: null,
    1: null,
    2: null,
  },
};

const emptyLine = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines }; //sipCallId
  updatedLines[action.index] = null;
  return updateObject(state, { lines: updatedLines });
};

const savedInCallAtLine = (
  state: ISoftPhoneReducer,
  action,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  updatedLines[action.index].isSavedInCall = action.isSavedInCall;
  return updateObject(state, { lines: updatedLines });
};

const emptyLineBySipCallId = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = {};
  _.forIn(state.lines, function (value, key) {
    if (value && value.sipCallId == action.sipCallId) {
      updatedLines[key] = null;
    } else updatedLines[key] = value;
  });

  return updateObject(state, { lines: updatedLines });
};

const acceptCall = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  return updateObject(state, {
    lines: action.lines,
    open: true,
    currentLine: action.currentLine,
  });
};

const toggleCallIsEnded = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  let callType = null;
  if (updatedLines[action.index]) {
    callType = updatedLines[action.index].callType;
    updatedLines[action.index].callIsEnded = action.state;
    updatedLines[action.index].hangupedAt = new Date();
  }
  const updatedFields: any = { lines: updatedLines };
  if (callType == callTypes.OUT) {
    updatedFields.phoneNumber = {...state.phoneNumber, [state.currentLine]: ''};
    updatedFields.dtmf = "";
    updatedFields.contextName = "";
  }
  return updateObject(state, updatedFields);
};

const toggleCallIsRegistered = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  updatedLines[action.index].callIsRegistered = action.state;
  return updateObject(state, { lines: updatedLines });
};

const toggleCallIsonHold = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  updatedLines[action.index].isOnHold = action.state;
  return updateObject(state, { lines: updatedLines });
};

const toggleCallIsMuted = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  updatedLines[action.index].isMuted = action.state;
  return updateObject(state, { lines: updatedLines });
};

const outCallIsAnswered = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  Object.values(updatedLines).forEach((obj) => {
    if (obj && obj.sipCallId === action.sipCallId) {
      obj.isAnswered = true;
    }
  });
  return updateObject(state, { lines: updatedLines });
};

const holdAllLinesExceptOne = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  _.forIn(updatedLines, function (value, key) {
    if (key != action.index) {
      if (value && !value.callIsEnded) value.isOnHold = true;
    }
  });
  return updateObject(state, { lines: updatedLines });
};

const updateWaitingDuration = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  _.forIn(updatedLines, function (value, key) {
    if (key == action.lineIndex) {
      if (value) value.waitingDuration = action.waitingDuration;
    }
  });
  return updateObject(state, { lines: updatedLines });
};
const updateDuration = (
  state: ISoftPhoneReducer,
  action: IAction,
): ISoftPhoneReducer => {
  const updatedLines = { ...state.lines };
  _.forIn(updatedLines, function (value, key) {
    if (key == action.lineIndex) {
      if (value) value.duration = action.duration;
    }
  });
  return updateObject(state, { lines: updatedLines });
};

const softPhoneReducer = (
  state = initialState,
  action: IAction,
): ISoftPhoneReducer => {
  switch (action.type) {
    case actionTypes.PHONE_CONNECTED:
      localStorage.setItem("phoneConnected", "true");
      return updateObject(state, { phoneConnected: true, phonePending: false });
    case actionTypes.PHONE_RECONNECTING:
      return updateObject(state, { phoneConnected: false, phonePending: true });
    case actionTypes.PHONE_DISCONNECTED:
      localStorage.setItem("phoneConnected", "false");
      return updateObject(state, {
        phoneConnected: false,
        phonePending: false,
      });
    case actionTypes.PHONE_PENDING:
      return updateObject(state, { phonePending: true });
    case actionTypes.SET_CURRENT_LINE:
      return updateObject(state, { currentLine: action.currentLine });
    case actionTypes.TOGGLE_SOFT_PHONE:
      return updateObject(state, { open: action.open });
    case actionTypes.SET_PHONE_NUMBER:
      return updateObject(state, { phoneNumber: action.phoneNumber });
    case actionTypes.SET_DTMF:
      return updateObject(state, { dtmf: action.dtmf });
    case actionTypes.SET_REGISTERED:
      return updateObject(state, { registered: action.registered });
    case actionTypes.SET_CONTEXT_NAME:
      return updateObject(state, { contextName: action.contextName });
    case actionTypes.SET_SAVED_IN_CALL:
      return updateObject(state, { isSavedInCall: action.isSavedInCall });
    case actionTypes.SET_CALL_CONTINUING:
      return updateObject(state, { isCallContinuing: action.isCallContinuing });
    case actionTypes.SET_BREAK_IN_CALL:
      return updateObject(state, { isBreakInCall: action.isBreakInCall });
    case actionTypes.SET_BREAK_ON_HOLD:
      return updateObject(state, { isBreakOnHold: action.isBreakOnHold });
    case actionTypes.SET_SAVE_BEFORE_HOLD_CALL_OVER:
      return { ...state, isSavedBeforeHoldCall: action.isSavedBeforeHoldCall };
    case actionTypes.SET_SAVE_AFTER_HOLD_CALL_OVER:
      return {
        ...state,
        willBeSavedAfterHoldCallOver: action.willBeSavedAfterHoldCallOver,
      };
    case actionTypes.TRIGGER_CALL:
      return updateObject(state, {
        triggerCall: action.triggerCall,
        open: true,
      });
    case actionTypes.TRIGGER_OUTGOING_CALL:
      return updateObject(state, {
        triggerOutgoingCall: action.triggerOutgoingCall,
        open: true,
      });
    case actionTypes.SET_LINES_PHONES:
      return updateObject(state, { lines: action.lines });
    case actionTypes.EMPTY_LINE:
      return emptyLine(state, action);
    case actionTypes.EMPTY_LINE_BY_SIP_CALL_ID:
      return emptyLineBySipCallId(state, action);

    case actionTypes.ACCEPT_CALL:
      return acceptCall(state, action);
    case actionTypes.TOGGLE_CALL_IS_ENDED:
      return toggleCallIsEnded(state, action);
    case actionTypes.TOGGLE_CALL_IS_REGISTERED:
      return toggleCallIsRegistered(state, action);

    case actionTypes.UPDATE_WAITING_DURATION:
      return updateWaitingDuration(state, action);

    case actionTypes.UPDATE_DURATION:
      return updateDuration(state, action);

    case actionTypes.TOGGLE_CALL_IS_ON_HOLD:
      return toggleCallIsonHold(state, action);

    case actionTypes.TOGGLE_CALL_IS_MUTED:
      return toggleCallIsMuted(state, action);

    case actionTypes.OUT_CALL_IS_ANSWERED:
      return outCallIsAnswered(state, action);

    case actionTypes.HOLD_ALL_LINES_EXCEPT_ONE:
      return holdAllLinesExceptOne(state, action);

    case actionTypes.SET_LINES_PHONES_AFTER_ACCEPTED:
      return { ...state, linesAfterAccepted: action.lines };

    case actionTypes.SET_PHONE_SIZE:
      return { ...state, size: action.size };

    case actionTypes.SET_CALL_INFO_STATUS:
      return { ...state, isCallInfoActive: action.status };

    case actionTypes.CONFRIM_REG_PAGE_SAVE:
      return { ...state, confrimRegPageSave: action.confrimRegPageSave };

    case actionTypes.SET_SPY_CALL_ACTIVE:
      return { ...state, isSpyCallActive: action.isSpyCallActive };

    case actionTypes.SET_IS_COMPLETE_CALL_BY_AGENT:
      return { ...state, isCompleteCallByAgent: action.isCompleteCallByAgent };

    case actionTypes.SET_SAVE_BEFORE_ACW_CALL_OVER:
      return { ...state, isSavedBeforeACWCall: action.isSavedBeforeACWCall };

    case actionTypes.SET_SAVE_AFTER_ACW_CALL_OVER:
      return {
        ...state,
        willBeSavedAfterACWCallOver: action.willBeSavedAfterACWCallOver,
      };

    case actionTypes.SET_ACW_CALL_OVER:
      return { ...state, isACWCallOver: action.isACWCallOver };
    case actionTypes.SET_SAVED_IN_CALL_AT_LINE:
      return savedInCallAtLine(state, action);
    default:
      return state;
  }
};

export default softPhoneReducer;
