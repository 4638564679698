/* eslint-disable react-hooks/exhaustive-deps */

import { Grid, createStyles, makeStyles } from "@material-ui/core";
import useEventListener from "@use-it/event-listener";
import { StoreContext } from "Components/Layout/AppLayout";
import useUpdateEffect from "Hook/useUpdateEffect";
import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import SoftPhone from "Store/Actions/SoftPhone";

import ActionButton from "./ActionButton";
import Digit from "./Digit";
import { InfoOutlinedIcon } from "./Icons";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 20,
    },
  }),
);

const playDigitSound = async (digit: string | number) => {
  const loadedAudio =
    await require(`Assets/sounds/dtmf-sounds/dtmf-${digit}.mp3`);
  const digitAudio = new Audio(loadedAudio);
  digitAudio.play();
};

export default function Digits({ makeCall }) {
  const MAX_PHONE_NUMBER_LENGTH = 20;
  const MAX_DTMF_LENGTH = 5;

  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  const softStore: any = useContext(StoreContext);

  const softPhoneStore = new SoftPhone(useDispatch());

  const callInfo = softPhoneState.lines[softPhoneState.currentLine];

  const handleDigitClick = (digit: string | number) => {
    playDigitSound(digit);

    if (!callInfo) {
      if (
        softPhoneState.phoneNumber[softPhoneState.currentLine].length >
        MAX_PHONE_NUMBER_LENGTH
      )
        return;
      softPhoneStore.setPhoneNumber({
        ...softPhoneState.phoneNumber,
        [softPhoneState.currentLine]:
          softPhoneState.phoneNumber[softPhoneState.currentLine] + digit,
      });
    } else {
      if (softPhoneState.dtmf.length > MAX_DTMF_LENGTH) return;
      softPhoneStore.setDTMF(softPhoneState.dtmf + digit);

      const sipCallId =
        softPhoneState.lines[softPhoneState.currentLine].sipCallId;
      softStore && softStore.sipStorage.sendDtmf(sipCallId, digit);
    }
  };

  const removeLastDigit = () => {
    if (!callInfo) {
      const phoneNumber = softPhoneState.phoneNumber[
        softPhoneState.currentLine
      ].slice(0, -1);
      softPhoneStore.setPhoneNumber({
        ...softPhoneState.phoneNumber,
        [softPhoneState.currentLine]: phoneNumber,
      });
    }
  };

  function handler({ key }): void {
    if (
      document.activeElement.tagName === "input" &&
      Number(chatBoxState.activeConversationId) === 0
    ) {
      if (isFinite(parseInt(key))) handleDigitClick(key);
      else if (key === "Backspace") removeLastDigit();
    }
  }

  useEventListener("keydown", handler);

  useUpdateEffect(() => {
    if (softPhoneState.triggerCall) {
      makeCall();
      softPhoneStore.triggerCall(false);
    }
  }, [softPhoneState.triggerCall]);

  const handleCallInfo = () => {
    if (!softPhoneState.isCallInfoActive)
      softPhoneStore.setCallInfoStatus(true);
  };

  const classes = useStyles();
  return useMemo(
    () => (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Digit number={1} handleClick={handleDigitClick} />
          </Grid>
          <Grid item xs={3}>
            <Digit number={2} handleClick={handleDigitClick} />
          </Grid>
          <Grid item xs={3}>
            <Digit number={3} handleClick={handleDigitClick} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Digit number={4} handleClick={handleDigitClick} />
          </Grid>
          <Grid item xs={3}>
            <Digit number={5} handleClick={handleDigitClick} />
          </Grid>
          <Grid item xs={3}>
            <Digit number={6} handleClick={handleDigitClick} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Digit number={7} handleClick={handleDigitClick} />
          </Grid>
          <Grid item xs={3}>
            <Digit number={8} handleClick={handleDigitClick} />
          </Grid>
          <Grid item xs={3}>
            <Digit number={9} handleClick={handleDigitClick} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            {callInfo && (
              <ActionButton
                Icon={InfoOutlinedIcon}
                onClick={handleCallInfo}
                iconClassName={{
                  fontSize: 25,
                  marginTop: 25,
                  marginLeft: 10,
                }}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <Digit number={0} handleClick={handleDigitClick} />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
    ),
    [
      softPhoneState.registered,
      softStore.callIsEstablishing.get(),
      softPhoneState.currentLine,
      softPhoneState.lines,
      softPhoneState.triggerCall,
      softPhoneState.phoneNumber,
      softPhoneState.dtmf,
      requestState.showRegistration,
      softPhoneState.isCallContinuing,
    ],
  );
}
