import {
  createStyles,
  makeStyles,
  SnackbarContent,
  Snackbar,
} from "@material-ui/core";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import IconButton from "Components/Buttons/IconButton";
import { StoreContext } from "Components/Layout/AppLayout";
import { notifyAgent } from "Notifications/DesktopNotification";
import React, { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import Call from "Store/Actions/Call";
import ChatBox from "Store/Actions/ChatBox";
import Request from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";

import { validatePhoneNumber } from "Utils/Utils";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "150px",
      height: "160px",
      marginBottom: "50%",
      borderRadius: "20px",
      padding: 0,
      paddingTop: "10px",
      display: "flex",
      flexDirection: "column",
      "& .MuiSnackbarContent-message": {
        fontSize: "1.2rem",
      },
      "& .MuiSnackbarContent-action": {
        marginLeft: 0,
        paddingLeft: 0,
      },
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
    },
    button: {
      margin: "20px",
      marginTop: "10px",
    },
    styleButton: {
      fontSize: 60,
    },
  }),
);

export default function CallSnackBar() {
  const { translate } = useTranslation();
  const softStore: any = useContext(StoreContext);
  const classes = useStyles();

  const request = new Request(useDispatch());
  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const chatBox = new ChatBox(useDispatch());
  const call = new Call(useDispatch());

  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  const handleClose = (
    _event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ): void => {
    if (reason === "clickaway") return;

    softStore.newIncomingCallId.set(null);
  };

  const acceptCall = (): void => {
    try {
      const newIncomingCallId = softStore.newIncomingCallId.get();

      if (!newIncomingCallId) return;

      softStore.sipStorage.acceptCall(newIncomingCallId);
      softStore.newIncomingCallId.set(null);

      let lineIndex = null;
      const updatedLines = { ...softPhoneState.lines };
      Object.keys(updatedLines).forEach((index) => {
        const line = updatedLines[index];
        if (line && line.sipCallId == softStore.newIncomingCallId.get()) {
          lineIndex = index;
          updatedLines[index].isAnswered = true;
          updatedLines[index].answeredAt = new Date();
          updatedLines[index].waitingDuration = Math.floor(
            (new Date().getTime() - updatedLines[index].createdAt.getTime()) /
              1000,
          );
        }
      });

      updatedLines[softPhoneState.currentLine] !== null &&
        softPhoneStore.acceptCall(updatedLines, lineIndex);

      softPhoneStore.toggle(true);
      chatBox.setActiveConversation(0);
      call.setActiveCallId(0);
      request.setShowRegistration(true);
      request.setMenuType(menuTypes.CALL);
      // request.setSelectedRequestType({
      //   id: 9,
      //   name: "Tamamlanmamış",
      // });

      request.addRequestOption(menuTypes.CALL, "0", {
        id: 9,
        name: "Tamamlanmamış",
      });
    } catch (e) {
      console.error("Could not accept call", e.getMessage());
    }
  };

  const terminateCall = (): void => {
    softPhoneStore.setSpyCallActive(false);
    softStore.sipStorage.terminateCall(softStore.newIncomingCallId.get());
  };

  const acceptCallRef = useRef(acceptCall);

  useEffect(() => {
    acceptCallRef.current = acceptCall;
  }, [softStore?.newIncomingCallId.get(), softPhoneState.lines]);

  const activeSession =
    softStore?.sipStorage &&
    softStore?.sipStorage.activeSessions[softStore?.newIncomingCallId.get()];

  const displayName = validatePhoneNumber(
    activeSession?.incomingInviteRequest?.message?.from?._displayName ??
      activeSession?.incomingInviteRequest?.message?.from?.uri?.raw?.user,
  );

  const callerName = activeSession ? displayName : "";

  if (softStore?.newIncomingCallId.get()) {
    const autoCall = localStorage.getItem("autoCall");
    const autoCallTimeout = localStorage.getItem("autoCallTimeout");
    if (autoCall == "true") {
      setTimeout(() => {
        acceptCallRef.current();
      }, parseInt(autoCallTimeout) * 1000);
    }
  }

  useEffect(() => {
    if (softStore?.newIncomingCallId.get())
      document.visibilityState !== "visible" && notifyViaDesktop();
  }, [softStore?.newIncomingCallId.get(), softPhoneState.lines]);

  const notifyViaDesktop = (): void => {
    const notification = notifyAgent(
      translate("softphone_incoming_call"),
      callerName,
    );
    if (notification)
      notification.onclick = () => {
        acceptCallRef.current();
        window.focus();
      };
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={Boolean(softStore?.newIncomingCallId.get())}
      onClose={handleClose}>
      <SnackbarContent
        className={classes.root}
        message={callerName}
        action={
          <div className={classes.buttonContainer}>
            <IconButton
              onClick={acceptCall}
              tooltipText={translate("softphone_accept_call")}
              Icon={PhoneInTalkIcon}
              buttonType={buttonTypes.success}
              styleButton={classes.styleButton}
              button={classes.button}
            />
            {localStorage.getItem("autoCall") != "true" && (
              <IconButton
                tooltipText={translate("softphone_hang_up")}
                onClick={terminateCall}
                Icon={PhoneMissedIcon}
                buttonType={buttonTypes.danger}
                styleButton={classes.styleButton}
                button={classes.button}
              />
            )}
          </div>
        }
      />
    </Snackbar>
  );
}
